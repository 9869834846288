/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

/**
 * @license
 * MyFonts Webfont Build ID 3614215, 2018-07-23T16:39:39-0400
 * 
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are 
 * explicitly restricted from using the Licensed Webfonts(s).
 * 
 * You may obtain a valid license at the URLs below.
 * 
 * Webfont: FrutigerLTPro-Light by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/frutiger/frutiger-pro-45-light/
 * 
 * Webfont: FrutigerLTPro-LightCn by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/frutiger/pro-47-light-condensed/
 * 
 * 
 * License: https://www.myfonts.com/viewlicense?type=web&buildid=3614215
 * Licensed pageviews: 15
 * Webfonts copyright: Copyright &#x00A9; 2014 - 2016 Monotype Imaging Inc. All rights reserved.
 * 
 * © 2018 MyFonts Inc
*/

@font-face {
  font-family: "FrutigerLTPro-Light";
  src: url("webfonts/372607_0_0.eot");
  src: url("webfonts/372607_0_0.eot?#iefix") format("embedded-opentype"),
    url("webfonts/372607_0_0.woff2") format("woff2"),
    url("webfonts/372607_0_0.woff") format("woff"),
    url("webfonts/372607_0_0.ttf") format("truetype");
}

@font-face {
  font-family: "FrutigerLTPro-LightCn";
  src: url("webfonts/372607_1_0.eot");
  src: url("webfonts/372607_1_0.eot?#iefix") format("embedded-opentype"),
    url("webfonts/372607_1_0.woff2") format("woff2"),
    url("webfonts/372607_1_0.woff") format("woff"),
    url("webfonts/372607_1_0.ttf") format("truetype");
}

/* Global */
* {
  box-sizing: border-box;
}

html,
body,
#root {
  width: 100%;
  height: 100vh;
  margin: 0;
  background: #ededed;
}

html {
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

body {
  font-family: "FrutigerLTPro-Light", Courier, "Lucida Grande",
    "Lucida Sans Unicode", "Lucida Sans", Geneva, Verdana, sans-serif;
  font-size: 14px;
  line-height: 1.4em;
  color: #000;
  overscroll-behavior: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Phone Landscape ( + ) */
@media only screen and (min-width: 480px) {
  body {
    font-size: 16px;
  }
}

/* Tablet Portrait ( + ) */
@media only screen and (min-width: 768px) {
  body {
    font-size: 18px;
  }
}

/* Tablet Landscape ( + ) */
@media only screen and (min-width: 992px) {
  body {
    font-size: 20px;
  }
}

/* Laptop ( + ) */
@media only screen and (min-width: 1280px) and (min-height: 700px) {
  body {
    font-size: 22px;
  }
}

/* Monitor ( + ) */
@media only screen and (min-width: 1440px) and (min-height: 700px) {
  body {
    font-size: 24px;
  }
}

/* Cloud Text Formating Hack */

.cloud-text p + p {
  margin-top: 10px;
}

.cloud-text ul {
  margin-top: 20px;
  list-style: disc;
  padding: 0 20px;
}

.cloud-text ul li {
  margin-top: 10px;
}
